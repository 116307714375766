html, body, #root {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  height: 100%;
  font-family: Lato;
  font-style: normal;
  color: #fff;
}
